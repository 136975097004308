
.payments-top-header-card {
    margin-right: 10px;
}


.payments-top-header-card-tittle {
    font-size: 14px;
    font-family: -webkit-body;
    font-weight: 500;
    color: #402a2acf;
}

.payments-top-header-card-content {
    font-size: 13px;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}

