

@media (max-width: 700px) {
    .forgot-password-card  {
      width: 100%;
      margin-top: 20%;
    }
  }
  
  @media (max-width: 900px) and (min-width: 700px) {
    .forgot-password-card  {
      width: 500px;
      margin-top: 20%;
    }
  }
  
  
  @media (min-width: 901px) {
    .forgot-password-card  {
      width: 500px;
      margin-top: 7%;
    }
  }
  
  