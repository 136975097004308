
/* MARKS PAGE */

@media (max-width: 994px) {
  .marks-tittle-col {
    margin-bottom: 1rem;
  }
}

@media (max-width: 778px) {
  .mark-schedule-container {
    overflow: auto;
    height: calc(100vh - 250px);
  }
}

@media ( max-width: 1000px) and ( min-width: 778px) {
  .mark-schedule-container {
    overflow: auto;
    height: calc(100vh - 200px);
  }
}

@media (min-width: 1000px) {
  .mark-schedule-container {
    overflow: auto;
    height: calc(100vh - 175px);
  }
}


/* TIMETABLE PAGE */
@media (max-width: 768px) {
  .timetable-action-buttons {
    width: 100%;
    margin-top: 8px;
  }
  
  .timetable-actions {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .timetable-actions {
    display: inline-flex;
    gap: 5px;
    float: right;
  }

}


/* ASSESSMENTS PAGE */

@media (max-width: 850px) {
  .tables-on-mobile {
    overflow-x: scroll;
  }
}
/* ASSESSMENTS PAGE */

@media (max-width: 700px) {
  .assessment-paper-input-field {
    margin-top: .5rem;
  }
}
/* SCHOOL PAGE  */
@media (max-width: 700px) {
  .school-page-widget  {
    padding-right: 22px;
    padding-top: 8px;
  }
}

@media (max-width: 900px) and (min-width: 700px) {
  .school-page-widget  {
    padding-right: 10px;
    padding-top: 8px;
  }
}


@media (min-width: 901px) {
  .school-page-widget  {
    padding-right: 5px;
    padding-top: 8px;
  }
}


@media (max-width: 901px) {
  .marks-tittle {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
}


/* MOBILE AND DESKTOP */
@media (max-width: 700px) {
  .mobile-view {
    width: 95%;
    display: block;
  }

  .desktop-view {
    display: none;
  }
}

@media (min-width: 701px) {
  .desktop-view {
    width: 95%;
    display: block;
  }

  .mobile-view  {
    display: none;
  }
}

/* Parent Dashboard */

@media (max-width: 700px) {
  .parent-dashboard-actions {
    width: 95%;
  }
  .parent-dashboard-actions  button {
    margin-top: 8px;
    margin-right: 5px;
  }
}

@media (max-width: 900px) {
  .parent-dashboard {
    width: 95%;
  }
}

@media (min-width: 901px) {
  .parent-dashboard {
    width: 80%;
  }
}

/* TERMS AND CONDTIONS CSS */
@media (max-width: 900px) {
  .termsAndConditions {
    width: 100%;
  }
}

@media (min-width: 901px) {
  .termsAndConditions {
    width: 60%;
  }
}

/* PROFILE PAGE */

@media (max-width: 750px) {
  .profile-action-button {
    width: 98%;
    margin-right: 9%;
  }
  .profile-shift-display .ant-card-body {
    padding: 0 !important;
  }
}

@media (min-width: 992px) {
  .layout-dashboard .ant-layout-footer {
    margin: 0 0 20px 20px;
  }
  .layout-dashboard .ant-layout-sider.sider-primary {
    margin: 20px 0 0 20px;
    padding: 13px 20px;
    height: calc(100vh - 20px);
  }
  .layout-dashboard .ant-layout {
    width: auto;
    flex-shrink: 1;
    margin-left: 270px;
  }
  .layout-dashboard .header-control .sidebar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .layout-dashboard .ant-layout-header {
    margin: 10px 20px;
  }
  .layout-dashboard .header-control {
    margin-top: 0;
  }
  .layout-dashboard .header-control .header-search {
    margin: 0 7px;
  }
  .layout-dashboard .header-control .btn-sign-in span {
    display: inline;
  }
  .profile-nav-bg {
    margin-top: -87.8px;
  }
  .card-profile-head {
    margin: -53px 24px 24px;
  }
}

@media (min-width: 992px) {
  .card-billing-info.ant-card .ant-card-body {
    display: flex;
  }
  .layout-dashboard-rtl {
    overflow: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    margin: 20px 20px 0 0;
    height: calc(100vh - 20px);
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout {
    margin-right: 270px;
    margin-left: 0;
  }
  .layout-dashboard-rtl .ant-layout-footer {
    margin: 0 20px 20px 0;
  }
}

@media (max-width: 768px) {
  .layout-dashboard.layout-dashboard-rtl .header-control {
    justify-content: flex-end;
  }
}

@media (max-width: 700px) {
  .roster-mobile-app-roster-actions {
    margin-top: 2%;
    padding-right: 2.8%;
  }
}

@media (max-width: 1200px) {
  .roster-mobile-app-roster-actions {
    padding-right: 2.8%;
  }

}



/* PROFILE CSS */

.profile-shift-card-date-picker {
  float: right !important;
}

@media (max-width: 992px) {

  .profile-shift-card-tittle-row {
    margin-left: 0 !important;
  }

  .profile-shift-card-date-picker {
    margin-top: 1%;
    float: left !important;
  }

}

@media (max-width: 992px) {
  
  .custom-roster-card {
    margin-left: 3%;
  }

}

@media (max-width: 992px) {
  
  .hide-option-label {
    display: none !important;
  }

}

.custom-roster-options-1 {
  width: fit-content;
  float: left;
}

.custom-roster-options-2 {
  width: 330px;
}

.custom-roster-options-3  {
  width: 410px;
}


@media (max-width: 1000px) {
  .custom-roster-options-1 {
    width: fit-content;
    float: left;
  }
  
  .custom-roster-options-2 {
    width: 180px;
  }
  
  .custom-roster-options-3  {
    width: 265px;
  }
  
}

@media (max-width: 700px) {

  .custom-roster-options-1, .custom-roster-options-2,  .custom-roster-options-3  {
    width: 100%;
    text-align: center !important;
  }

  .custom-roster-options-3, .custom-roster-options-2 {
    margin-top: 1%;
  }

}

@media (max-width: 900px) {
  .new-chat-bot-container {
    width: 100%;
  }
}

@media (min-width: 900px) {

  .new-chat-bot-container {
    width: 70%;
  }

}

