
.positive-performance {
    color: green;
    font-weight: bold;
    font-size: 15px;
}

.negative-performance {
    color: red;
    font-weight: bold;
    font-size: 15px;
}

.constant-performance {
    color: blue;
    font-weight: bold;
    font-size: 15px;
}

.student-academic-results-slip-container {
    min-width: 590px;
    font-family: Arial, sans-serif;
}
.student-academic-results-slip-header {
    text-align: center;
    width: 100%;
}

.student-academic-results-slip-student-detail-card {
    width: 100%;
    display: inline-flex;
}

.student-academic-results-slip-header img {
    width: 50mm; /* Adjusted for A4 */
    float: left;
}
.student-academic-results-slip-header h1 {
    margin: 0;
    font-size: 18px;
}
.student-academic-results-slip-header p {
    margin: 0;
    font-size: 12.3px;
    padding: 0.2px;
}
.student-academic-results-slip-school-details, 
.student-academic-results-slip-student-details, 
.student-academic-results-slip-results {
    margin: 1.5px 0;
}
.student-academic-results-slip-school-details p, 
.student-academic-results-slip-student-details p {
    margin: 5px 0;
}

.student-academic-results-slip-student-details  {
    font-size: 12.8px;
}
.student-academic-results-slip-table {
    width: 100%;
    border-collapse: collapse;
    margin: 10px 0;
}
.student-academic-results-slip-table, 
.student-academic-results-slip-th, 
.student-academic-results-slip-td {
    border: .001px solid #000;
}
.student-academic-results-slip-th, 
.student-academic-results-slip-td {
    padding: 5px;
    text-align: left;
    font-size: 12px;
}
.student-academic-results-slip-th {
    color: #0d0d0d;
}
.student-academic-results-slip-teacher-comment {
    margin-top: 8px;
    font-style: italic;
    font-size: 12px;
}

.student-academic-results-slip-logo-frame {
    width: 80px;
    height: 70px;
    overflow: hidden;
}

.student-academic-results-slip-student-photo {
    width: 80px;
    height: 70px;
    overflow: hidden;
    border-radius: 5px;
}
.student-academic-results-slip-logo { 
    width: 100%;
    object-fit: cover;
    display: block;
    height: 100%;
}

.student-academic-results-slip-parent {
    display: inline-flex;
    width: 100%;
}
.student-academic-results-slip-left-content {
    padding: 0px;
}
.student-academic-results-slip-right-content {
    padding: 10px;
    margin-left: auto;
}

.student-academic-results-slip-watermark {
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.15; /* Adjust opacity for watermark effect */
    width: 50%; /* Adjust size based on your requirement */
    height: auto;
    z-index: 0; /* Ensure it stays behind the table content */
}

.student-academic-results-slip-table {
    position: relative;
    z-index: 1; /* Table content stays above the watermark */
}


.print-button {
    margin-top: 2rem;
}


.results-page-float-back-to-btn .ant-float-btn-body {
    background-color:  #879ad7 !important;
    color: wheat;
}