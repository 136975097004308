

.custom-btn-sm {
    line-height: 0px !important;
    height: 30px !important;
}

@media (min-width: 992px) {
    :where(.css-dev-only-do-not-override-djtmh8) .learning-materials-row .ant-col-lg-5 {
        --ant-display: block;
        display: block;
        display: var(--ant-display);
        flex: 0 0 20%;
        max-width: 20%;
    }

}

.learning-material-page-tittle {
    margin-left : 2rem
}

@media (max-width: 992px) {
    .learning-material-page-tittle {
        margin-left : 10%
    }
}




.parent-side-learning-materials {
    margin-top : 8%;
}


@media (min-width: 992px) {
    .parent-side-learning-materials {
        margin-top : 5%;
        margin-left : 5rem;
    }
}

.learning-material-card {
    font-size: 13px;
}

.learning-material-doc-tittle {
    font-size: 14px;
}

.learning-material-card .ant-card-head {
    min-height: 40px ;
}

.learning-material-card .ant-card-head .ant-card-head-wrapper {
    min-height: 10px;
}

.learning-material-doc-description p {
    font-size: 12px;
    margin: 0px;
}