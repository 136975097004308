
.student_results_slip-container {
    width: 210mm;
    min-height: 297mm;
    margin: auto;
    padding: 20mm;
    box-sizing: border-box;
    page-break-after: always;
}

.student_results_slip-header {
    text-align: center;
    border-bottom: 2px solid #000;
    padding-bottom: 10px;
    display: inline-flex;
    width: 100%;
}

.student_results_slip-student-detail-card {
    width: 100%;
    display: inline-flex;
}

.student_results_slip-header img {
    width: 100%;
    float: left;
    height: 100%;
    object-fit: cover;
}

.student_results_slip-header h1 {
    margin: 0;
    font-size: 24pt;
}

.student_results_slip-header p {
    margin: 0;
    font-size: 12pt;
}

.student_results_slip-school-details,
.student_results_slip-student-details,
.student_results_slip-results {
    margin: 10px 0;
}

.student_results_slip-school-details p,
.student_results_slip-student-details p {
    margin: 5px 0;
}

.student_results_slip-table {
    width: 100%;
    border-collapse: collapse;
    margin: 10px 0;
}

.student_results_slip-table  tr th, .student_results_slip-table  tr td  {
    border : 1px solid #ddd;
    padding :  2.25px;
    font-size: 12.5px;
    text-align: center;
  }
  

.student_results_slip-table, .student_results_slip-th, .student_results_slip-td {
    border: .01px solid #423232;
}

.student_results_slip-th, .student_results_slip-td {
    padding: 2px;
    text-align: left;
    font-size: 11.8px;
}

.student_results_slip-th {
    background-color: #0033A0;
    color: #fff;
    font-size: 11.8px;
}

.student_results_slip-teacher-comment {
    margin-top: 8px;
    font-style: italic;
    font-size: 13px;
}

.student_results_slip-logo-frame {
    width: 120px;
    height: 105px;
    overflow: hidden;
}

.student_results_slip-student-photo {
    width: 120px;
    height: 105px;
    overflow: hidden;
}

.student_results_slip-logo { 
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.student_results_slip-parent {
    display: inline-flex;
    width: 100%;
}

.student_results_slip-left-content {
    padding: 0px;
}

.student_results_slip-right-content {
    padding: 10px;
    margin-left: auto;
}

.student_results_slip-school-info {
    width: fit-content;
    margin-left: 3.5rem;
}
