.student-summary-card {
    border: 2px solid #00ff66;
    border-radius: 8px;
    padding: 0.5rem;
  }
  
  .student-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .student-summary-card-subtitle {
    margin: 4px 0 0;
    font-size: 12px;
    color: #555;
  }
  