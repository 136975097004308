/* For the entire scrollbar */
::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
    height: 12px; /* Height of the scrollbar (for horizontal scrollbar) */
  }
  
  /* Background of the scrollbar track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track color */
    border-radius: 10px; /* Rounded corners for the track */
  }
  
  /* Scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background: #ccc6c6; /* Scrollbar color */
    border-radius: 10px; /* Rounded corners for the thumb */
  }
  
  /* On hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color when hovering over the scrollbar */
  }
  