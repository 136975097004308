


@media (min-width: 700px) {
    .login-page-system-name {
        font-size: 25px !important;
    }
}

@media (max-width: 700px) {
    .login-page-system-name {
        font-size: 20px !important;
    }
}