@media (max-width: 1500px) {
    .discription-during {
      max-width: 260px; 
      overflow : hidden;
    }
  }

  @media (min-width: 1501px) {
    .discription-during {
      max-width: 400px; 
      overflow : hidden;
    }
  }