.new-dashboard-card-title {
    margin: 0;
    font-size: 15px;
  }

  :where(.css-dev-only-do-not-override-apn68).student-custom-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #00ff66 !important;
    background: #ffffff;
}

.new-dashboard-card {
  min-height: 100%;
  max-height: 100%;
}