.student-finance-container {
    min-height: 297mm;
    margin: auto;
    box-sizing: border-box;
    page-break-after: always;
    
}

@media (max-width: 900px) {
    .student-finance-container {
        width: 98%;
        padding-left: 2mm;
        margin-top: -10px;
    }
    .student-finance-name {
        margin-left: 12px;
    }
    .student-finance-balance {
        margin-left: 12px;
    }
    .student-finance-home-btn {
        margin-right: 12px;
    }
    .student-finance-tables {
        margin-left: 2px;
    }
    .student-finance-payments-container {
        margin-right: 15px;
        width: 100%;
    }
  }

@media (min-width: 900px) {
    .student-finance-container {
        width: 61%;
    }
    .student-finance-name {
        margin-left: 0px;
    }
    .student-finance-payments-container {
        width: 350px;
        margin-right: 10px;
    }
  }