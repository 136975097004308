

.chat-app {
    display: flex;
    height: 75vh;
  }

  @media (max-width: 800px) {
    .chat-message  {
      max-width: 500px;
    }
    .chat-app {
      /* height: 64vh; */
    }
  }
  
  .sidebar {
    width: 300px;
    background-color: #fafafa;
    color: #4a4a4a;
    padding: 10px;
    overflow-y: auto;
  }

  @media screen and (max-width: 968px) {
    .sidebar {
      display: none; /* Hide the sidebar */
    }
  }
  
  .conversation-item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
  }
  
  .conversation-item:hover {
    background-color: #e9e6e6;
  }
  
  .conversation-initials {
    width: 40px;
    height: 40px;
    background-color: #c4b2b2;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
  
  .conversation-info {
    margin-left: 10px;
  }
  
  .conversation-name {
    font-weight: bold;
  }
  
  .conversation-preview {
    color: #b5b5b5;
  }
  
  .main-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  
  .chat-window {
    flex-grow: 1;
    background-color: #fafafa;
    color: #fff;
    padding: 10px;
    overflow-y: auto;
  }
  
  .chat-header {
    padding: 10px;
    /* background-color: #fafafa; */
    color: #2e2e2e;
    font-weight: bold;
    font-size: 20px;
  }
  
  .chat-messages {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
  }
  
  .chat-message {
    margin-bottom: 10px;
    max-width: 60%;
    max-width: 800px;
  }
  
  @media (max-width: 800px) {
    .chat-message  {
      max-width: 500px;
    }
  }
  .chat-message.sent {
    align-self: flex-end;
    padding: 10px;
    border-radius: 10px 10px 0 10px;
    background: linear-gradient(135deg, rgb(42, 39, 218), rgb(0, 204, 255));
    color: rgb(255, 255, 255);
    transition: transform 0.2s, margin 0.2s;
  }
  
  .chat-message.received {
    align-self: flex-start;
    background-color: #f0f2f7;
    padding: 10px;
    color: rgb(6, 19, 43);
    border-radius: 10px 10px 10px 0;
  }
  
  .message-text {
    font-size: 14px;
  }
  
  .message-input {
    display: flex;
    padding: 10px;
    background-color: #fafafa;
  }
  
  .message-input .message-textarea {
    flex-grow: 1;
    padding: 10px;
    border: none;
    border-radius: 20px;
    background-color: #efe2e2;
    color: #2e2e2e;
    outline: none;
  }
  
  .message-input button {
    margin-left: 10px;
    padding: 10px 20px;
    background-color: #4a4a4a;
    border: none;
    border-radius: 20px;
    color: #fff;
    cursor: pointer;
    height: 27px;
    bottom: 0;
    line-height: 0;
    align-self: flex-end; /* Ensures the button aligns with the bottom of the flex container */
  }
  

  .message-input .file-input-container {
    margin-left: 10px;
    height: 27px;
    bottom: 0;
    line-height: 0;
    align-self: flex-end; /* Ensures the button aligns with the bottom of the flex container */
  }
  
  .group-header {
    display: flex;
    align-items: center; /* Vertically centers the items */
    justify-content: space-between; /* Positions the "Groups" text on the left and the button on the right */
    width: 100%; /* Ensures the container takes up the full width */
  }
  
  .group-header div {
    margin-right: 10px; /* Optional: Adds space between the text and button */
  }
  
  .group-header Button {
    margin-left: auto; /* Pushes the button to the right */
  }
  

  .custom-list-item {
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }

  .custom-list-item:hover {
    box-shadow: 0px 4px 8px rgba(208, 169, 169, 0.2); /* Shadow on hover */
  }

  .custom-list-item-topic {
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    height: 33px;
    padding-top: 5px;
  }

  .custom-list-item-topic:hover {
    box-shadow: 0px 4px 8px rgba(208, 169, 169, 0.2); /* Shadow on hover */
  }