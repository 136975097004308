

.custom-row-height {
    height: 40px !important; /* or any desired height */
  }

  .even-row {
    background-color: aliceblue; /* or any desired height */
  }
  
  .odd-row {
    background-color: #fdffff; /* or any desired height */
  }
  


  .custom-cell-height .ant-table-cell {
    height: 40px; /* or any desired height */
    line-height: 10px; /* Make sure the text is vertically centered */
    padding : 0
  }
  

  /* Add this to your CSS file */
.custom-row-height {
  height: 16px !important;
}

.custom-cell-height .ant-table-cell {
  font-size: 12.4px !important;
  padding: 2px 4px !important; /* Adjust the padding as needed */
}
